import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/HatchfulExport-All/logo_relation_transparent.png'
import instagram from '../img/social/instagram.png'
import line from '../img/social/line.png'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  toReserve() {
    window.open("https://reserva.be/yuruyoga2020");
  }
  toInstagram() {
    window.open("https://www.instagram.com/yuruyoga2020/?hl=ja");
  }
  toLine() {
    window.open("https://lin.ee/XpA3coJ");
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item logo" title="Logo">
              <img src={logo} alt="Logo" style={{ maxHeight: '2rem' }} />
            </Link>
            <span className="is-hidden-desktop"
              style={{
                padding: '0.5rem 0',
                margin: '0 4px',
                display: 'flex'
              }}>
              <img
                  src={line}
                  alt="Line"
                  style={{
                    height: '40px',
                    boxSizing: 'none',
                    width: '40px',
                    margin: '0 0',
                  }}
                  alt="line"
                  onClick={this.toLine.bind()}
              ></img>
            </span>
            <span className="is-hidden-desktop"
              style={{
                padding: '0.5rem 0',
                margin: '0 4px',
                display: 'flex'
              }}>
              <img
                  src={instagram}
                  alt="Instagram"
                  style={{
                    height: '40px',
                    boxSizing: 'none',
                    width: '40px',
                    margin: '0 0',
                  }}
                  alt="instagram"
                  onClick={this.toInstagram.bind()}
              ></img>
            </span>
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/">Home</Link>
              <Link className="navbar-item" to="/about">About</Link>
              <a className="navbar-item" onClick={this.toReserve.bind()}>Schedule</a>
              <Link className="navbar-item" to="/gallery">Gallery</Link>
              <Link className="navbar-item" to="/blog">
                Blog
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
              <span className="is-hidden-touch" onClick={this.toLine.bind()}>
                <img
                    src={line}
                    alt="line"
                    style={{
                      borderRadius: '33%',
                      height: '70px',
                      boxSizing: 'none',
                      width: '70px',
                      padding: '10px',
                      cursor: 'pointer'
                    }}
                ></img>
              </span>
              <span className="is-hidden-touch" onClick={this.toInstagram.bind()}>
                <img
                    src={instagram}
                    alt="Instagram"
                    style={{
                      borderRadius: '33%',
                      height: '70px',
                      boxSizing: 'none',
                      width: '70px',
                      padding: '10px',
                      cursor: 'pointer'
                    }}
                ></img>
              </span>
            </div>
            <div className="navbar-end has-text-centered is-hidden-touch">
              <button className="button reserve-btn" onClick={this.toReserve.bind()}>
                ご予約はこちら
              </button>
            </div>
          </div>
          <div className="fixed-mobile is-hidden-desktop" style={{
            position: 'fixed',
            bottom: '44px',
            right: '12px',
          }}>
            <div>
              <button className="button reserve-btn" style={{
                borderRadius: '33%',
                height: '70px',
                boxSizing: 'none',
                width: '70px'
              }}
              onClick={this.toLine.bind()}>
                ご予約
              </button>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
